import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
export default {
  init: function init() {
    //                console.log('page-request-init');
    var hash = $.trim(location.hash.slice(1));
    //                console.log(hash);
    if (hash !== '') {
      $('body').find('a[data-trigger="' + hash + '"]').first().click();
    }
  },
  finalize: function finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};