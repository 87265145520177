import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.number.parse-int.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.link.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
export default {
  init: function init() {
    $.fn.hasAttr = function (name) {
      return $(this)[0].hasAttribute(name);
    };
    $.fn.exists = function () {
      return this.length !== 0;
    };

    // JavaScript to be fired on all pages
    $(".mob-sidebar-btn").click(function () {
      $(this).parents(".mob-header").first().toggleClass("side-icon-toggle");
      $("#sid_main").toggleClass("sid-menu-open");
    });

    /*$('.mega-menulink').click(function () {
        $('#megamenu').addClass('modal--in');
         $('.modal__nav__container .modal__nav__item').each(function (i) {
            var $li = $(this).children();
            setTimeout(function () {
                $li.addClass('show');
            }, i * 250); // delay 100 ms
        });
    });
     $('#closemegamenu").click(function () {
        $('#megamenu').removeClass('modal--in');
    });*/

    $("#helpdesk").click(function () {
      $("#helpform").addClass("modal--in");
    });
    $("#helpdeskclose").click(function () {
      $("#helpform").removeClass("modal--in");
    });
    $(".mobile-navigation__label").click(function () {
      var subMenuList = $(".mobile-navigation__submenu > ul");
      $(".mobile-navigation__label > span").toggleClass("ui-unfold");
      $(".mobile-navigation__label > span").toggleClass("ui-fold");
      subMenuList.toggleClass("mobile-navigation__submenu--close");
      subMenuList.toggleClass("mobile-navigation__submenu--open");
    });
    var $assetcards = $("#assetscards").owlCarousel({
      margin: 32,
      loop: false,
      autoWidth: true,
      nav: false,
      items: 5
    });
    $assetcards.on("mousewheel", ".owl-stage", function (e) {
      if (e.deltaY > 0) {
        $assetcards.trigger("prev.owl");
      } else {
        $assetcards.trigger("next.owl");
      }
      e.preventDefault();
    });

    /*$('#promo_campign').owlCarousel({
        margin: 0,
        loop: false,
        items: 1,
        nav: true
    });*/

    var $assetnav = $("#assetnav").owlCarousel({
      loop: false,
      margin: 20,
      nav: true,
      autoWidth: true,
      responsive: {
        0: {
          items: 3
        },
        600: {
          items: 5
        },
        1000: {
          items: 7
        }
      }
    });
    $assetnav.on("mousewheel", ".owl-stage", function (e) {
      if (e.deltaY > 0) {
        $assetnav.trigger("prev.owl");
      } else {
        $assetnav.trigger("next.owl");
      }
      e.preventDefault();
    }).on("translated.owl.carousel", function (e) {
      var $new_class = getAssetOwlClass();
      $assetnav.find(".owl-stage-outer").removeClass("more-right").removeClass("more-left").removeClass("more-both").addClass($new_class);
      e.preventDefault();
    });

    /***asset page left nav*/
    $(".open-list").on("click", function () {
      $(".open-list").removeClass("selected");
      $(this).addClass("selected");
    });
    $("#newUserRequest").on("click", function () {
      $("#olduser_form").hide();
      $("#newuser_form").fadeIn();
    });
    $("#oldUserRequest").on("click", function () {
      $("#newuser_form").hide();
      $("#olduser_form").fadeIn();
    });
    var selectModal = function selectModal(selector) {
      var target = $(selector);
      if (target.length === 0) {
        return;
      } else if (target.length > 1) {
        target = target.filter(".default");
      }
      var modal = target.find(".modal");
      modal.addClass("modal--in");
    };

    // Requests Flow
    $("body").on("click", ".request-link, .flow-next-btn", function (e) {
      e.preventDefault();
      $(this).parents(".modal").first().removeClass("modal--in");
      var selector = $(this).data("target");
      selectModal(selector);
    });
    $(".popup-link a").click(function (e) {
      e.preventDefault();
      var selector = $(this).attr("href");
      var modal = $("main").find(selector).find(".modal");
      modal.addClass("modal--in");
    });
    $(".modal .button--close").click(function (e) {
      e.preventDefault();
      $(this).parents(".modal").first().removeClass("modal--in");
    });

    /****| filter |*****/

    $(".filter__link").on("click", function () {
      $("#filter_link").addClass("dn");
      $(".filter__details").addClass("animated fadeIn");
    });
    var tagList = [];
    (function (parentSelector) {
      function updateFilters() {
        var isNoResults = true;
        if (tagList.length > 0) {
          $(parentSelector + "__list--items").fadeOut();
          $(parentSelector + "__list--items").each(function (i, data) {
            var campfilter = $(data).attr("data-campfilter").split(",").map(function (d) {
              return d.toLowerCase();
            }).filter(function (d) {
              console.log("is D Not Equal to Empty: ", d !== "");
              return d !== "";
            });
            var isPresent = tagList.every(function (val) {
              return campfilter.indexOf(val.toLowerCase()) >= 0;
            });
            if (isPresent) {
              $(this).fadeIn();
              isNoResults = false;
            }
            console.log("==================");
            console.log(" ");
            console.log(" ");
          });
          if (isNoResults) {
            $(".no__results").fadeIn();
          } else {
            $(".no__results").fadeOut();
          }
        } else {
          $(parentSelector + "__list--items").fadeIn();
          $(".no__results").fadeOut();
        }
      }
      $(".campaign_filter.closefilter__action").click(function () {
        $("#filter_link").removeClass("dn");
        $(".filter__details").removeClass("animated fadeIn");
        updateFilters();
      });
      /* -------- FUNCTIONS -------- */
      function selectFilter() {
        var selectedElem = '<a class="selected__val">' + $(this).text() + "</a>";
        $(this).addClass("hide");
        tagList.push($(this).text());
        $(".selected__filter > div > span.selected-value__container").append(selectedElem);
        updateFilters();
      }
      function removeFilter() {
        var removeElemText = $(this).text();
        $(this).remove();
        tagList = tagList.filter(function (item) {
          return item !== removeElemText;
        }.bind($(this)));
        $(".list__items > .items > a.hide").each(function (i, elem) {
          if (removeElemText === $(elem).text()) {
            $(this).removeClass("hide");
          }
        });
        updateFilters();
      }
      function clearAll() {
        $(".list__items > .items > a.hide").each(function (i, elem) {
          if ($.inArray($(elem).text(), tagList) > -1) {
            $(elem).removeClass("hide");
          }
        });
        $(".selected__filter > div > span.selected-value__container").html("");
        tagList = [];
        updateFilters();
      }
      function showMore(e) {
        $(e.delegateTarget).find(".hide-more").each(function (i, e) {
          $(e).removeClass("hide-more");
        });
        $(this).hide();
      }

      /* Listener when element added to the selected-filter */
      $(".campaign .list__items > .items > a").click(selectFilter);
      /* Listener to remove element from the selected-filter */
      $(".selected__filter span.selected-value__container").on("click", "a", removeFilter);
      /* Listener to remove element from the selected-filter */
      $("a.clearall").click(clearAll);
      /* Listener to show-more */
      $(".list__items").on("click", "div.show__more", showMore);
    })(".brand_resources");
    (function (parentSelector) {
      function updateLogoFilters() {
        var isNoResults = true;
        if (tagList.length > 0) {
          $(parentSelector + "__list--items").fadeOut();
          $(parentSelector + "__list--items").each(function (i, data) {
            var campfilter = $(data).attr("data-campfilter").split(",").map(function (d) {
              return d.toLowerCase();
            }).filter(function (d) {
              return d !== "";
            });
            var isPresent = tagList.every(function (val) {
              return campfilter.indexOf(val.toLowerCase()) >= 0;
            });
            if (isPresent) {
              $(this).fadeIn();
              isNoResults = false;
            }
          });
          if (isNoResults) {
            $(".no__results").fadeIn();
          } else {
            $(".no__results").fadeOut();
          }
        } else {
          $(parentSelector + "__list--items").fadeIn();
          $(".no__results").fadeOut();
        }
      }
      $(".logos_filter.closefilter__action").click(function () {
        $("#filter_link").removeClass("dn");
        $(".filter__details").removeClass("animated fadeIn");
        updateLogoFilters();
      });
      /* -------- FUNCTIONS -------- */
      function selectLogoFilter() {
        var selectedElem = '<a class="selected__val">' + $(this).text() + "</a>";
        $(this).addClass("hide");
        $(".section_title").css("visibility", "hidden");
        tagList.push($(this).text());
        $(".selected__filter > div > span.selected-value__container").append(selectedElem);
        updateLogoFilters();
      }
      function removeLogoFilter() {
        var removeElemText = $(this).text();
        $(this).remove();
        tagList = tagList.filter(function (item) {
          return item !== removeElemText;
        }.bind($(this)));
        $(".list__items > .items > a.hide").each(function (i, elem) {
          if (removeElemText === $(elem).text()) {
            $(this).removeClass("hide");
            $(".section_title").css("visibility", "visible");
          }
        });
        updateLogoFilters();
      }
      function clearAllLogo() {
        $(".list__items > .items > a.hide").each(function (i, elem) {
          if ($.inArray($(elem).text(), tagList) > -1) {
            $(elem).removeClass("hide");
          }
        });
        $(".section_title").css("visibility", "visible");
        $(".selected__filter > div > span.selected-value__container").html("");
        tagList = [];
        updateLogoFilters();
      }
      function showMoreLogo(e) {
        $(e.delegateTarget).find(".hide-more").each(function (i, e) {
          $(e).removeClass("hide-more");
        });
        $(this).hide();
      }

      /* Listener when element added to the selected-filter */
      $(".logos .list__items > .items > a").click(selectLogoFilter);
      /* Listener to remove element from the selected-filter */
      $(".selected__filter span.selected-value__container").on("click", "a", removeLogoFilter);
      /* Listener to remove element from the selected-filter */
      $("a.clearall").click(clearAllLogo);
      /* Listener to show-more */
      $(".list__items").on("click", "div.show__more", showMoreLogo);
    })(".brand_download");
    function getAssetOwlClass() {
      var $asset_list = $assetnav.find(".owl-item");
      var $first_asset = $($asset_list[0]).hasClass("active");
      var $last_asset = $($asset_list[$asset_list.length - 1]).hasClass("active");
      if ($first_asset && !$last_asset) {
        return "more-right";
      } else if (!$first_asset && $last_asset) {
        return "more-left";
      } else {
        return "more-both";
      }
    }
    function sortDescending(a, b) {
      var date1 = $(a).find(".year").text();
      date1 = date1.split("/");
      date1 = new Date(date1[2], date1[1] - 1, date1[0]);
      var date2 = $(b).find(".year").text();
      date2 = date2.split("/");
      date2 = new Date(date2[2], date2[1] - 1, date2[0]);
      return date1 < date2;
    }
    function sortAscending(a, b) {
      var date1 = $(a).find(".year").text();
      date1 = date1.split("/");
      date1 = new Date(date1[2], date1[1] - 1, date1[0]);
      var date2 = $(b).find(".year").text();
      date2 = date2.split("/");
      date2 = new Date(date2[2], date2[1] - 1, date2[0]);
      return date1 > date2;
    }
    $(".recentFilter").click(function () {
      $(".brand_resources__list .brand_resources__list--items").sort(sortDescending).appendTo(".brand_resources__list");
    });
    $(".oldestFilter").click(function () {
      $(".brand_resources__list .brand_resources__list--items").sort(sortAscending).appendTo(".brand_resources__list");
    });
    if (window.location.hostname === "ourbrand.bbvaexperience.com") {
      $(".help-form").attr("id", "form_jdluxcpu1521752343387");
      $(".request-form-assets").attr("id", "form_jdluxcpu1521822934445");
      $(".request-form-istock-getty").attr("id", "form_jdluxcpu1521822790038");
      $(".request-form-domain").attr("id", "form_jdluxcpu1521822858494");
      $(".request-form-photography").attr("id", "form_jdluxcpu1521823016304");
      $(".request-form-trademark").attr("id", "form_jdluxcpu1521823074485");
      $(".request-form-photography-campaign").attr("id", "form_jdluxcpu1522435447669");
      $(".request-form-photography-limited").attr("id", "form_jdluxcpu1527784659115");

      // console.log('Our Brand' + window.location.hostname);
    } else {
      $(".help-form").attr("id", "form_j95d9zta1520526008573");
      $(".request-form-assets").attr("id", "form_j95d9zta1520617155873");
      $(".request-form-istock-getty").attr("id", "form_j95d9zta1520613243385");
      $(".request-form-domain").attr("id", "form_j95d9zta1520617104532");
      $(".request-form-photography").attr("id", "form_j95d9zta1520617194375");
      $(".request-form-trademark").attr("id", "form_j95d9zta1520617224595");
      $(".request-form-photography-campaign").attr("id", "form_j95d9zta1522381388879");
      $(".request-form-photography-limited").attr("id", "form_j95d9zta1524152982474");

      // console.log('Not Our Brand' + window.location.hostname);
    }

    /***onscroll page animation****/
    $(window).scroll(function () {
      /* Check the location of each desired element */
      $(".dofadein").each(function () {
        var bottom_of_object = $(this).offset().top + $(this).outerHeight();
        var bottom_of_window = $(window).scrollTop() + $(window).height();

        /* If the object is completely visible in the window, fade it it */
        if (bottom_of_window > bottom_of_object) {
          $(this).addClass("fadeIn animated");
        } else {
          $(this).removeClass("fadeIn");
        }
      });
    });

    /* Check the location of each desired element */
    $(".doflipin").each(function () {
      var bottom_of_object = $(this).offset().top + $(this).outerHeight();
      var bottom_of_window = $(window).scrollTop() + $(window).height();

      /* If the object is completely visible in the window, fade it it */
      if (bottom_of_window > bottom_of_object) {
        $(this).addClass("flipInX animated");
      } else {
        $(this).removeClass("flipInX");
      }
    });
    $(window).load(function () {
      /* Check the location of each desired element */
      $(".dofadein").each(function () {
        var bottom_of_object = $(this).offset().top + $(this).outerHeight();
        var bottom_of_window = $(window).scrollTop() + $(window).height();

        /* If the object is completely visible in the window, fade it it */
        if (bottom_of_window > bottom_of_object) {
          $(this).addClass("fadeIn animated");
        }
      });
      var total_nav_assets = $(".brand_assets__tab .tab__navigation .owl-item").length;
      var active_asset_position = 0;
      $(".brand_assets__tab .tab__navigation .owl-item").each(function (i) {
        var $a = $(this).children();
        if ($a.hasClass("active")) {
          active_asset_position = i;
        }
        setTimeout(function () {
          $a.addClass("fadeIn animated");
        }, 250); // delay 100 ms
      });

      //Slide carousel to active asset
      if (Number.parseInt(active_asset_position) > 7) {
        $assetnav.trigger("to.owl.carousel", [active_asset_position + 1]);
        if (Number.parseInt(active_asset_position) + 1 != total_nav_assets) {
          $assetnav.find(".owl-stage-outer").removeClass("more-right").removeClass("more-left").addClass("more-both");
        } else {
          $assetnav.find(".owl-stage-outer").removeClass("more-both").removeClass("more-right").addClass("more-left");
        }
      } else {
        $assetnav.find(".owl-stage-outer").removeClass("more-both").removeClass("more-left").addClass("more-right");
      }
    });
    var min_length = 3;
    var search_input = document.querySelector(".search__input");
    function doSearch(event) {
      if (search_input.value.length > min_length) {
        event.preventDefault();
        var action = document.querySelector(".search__form").getAttribute("action");
        var href = action + "?q=" + search_input.value;
        var a = document.createElement("a");
        a.setAttribute("href", href);
        a.click();

        //document.querySelector('.search__form').submit();
        //window.location.href = '/private/component-search?q=' + search_input.value;
      }
    }

    if (undefined != document.querySelector("ul.search a")) {
      document.querySelector("ul.search a").addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector("div.main-nav-links .logo-container").classList.toggle("hide");
        document.querySelector("div.main-nav-links .link-container").classList.toggle("hide");
        document.querySelector("div.search").classList.toggle("search--open");
        document.querySelector("div.main-nav-links .search__bar .search__label input").focus();
      });
    }
    if (undefined != document.querySelector(".search__close-btn")) {
      document.querySelector(".search__close-btn").addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector("div.search").classList.toggle("search--open");
        document.querySelector("div.main-nav-links .logo-container").classList.toggle("hide");
        document.querySelector("div.main-nav-links .link-container").classList.toggle("hide");
        return false;
      });
    }
    if (undefined != document.querySelector(".search__input")) {
      document.querySelector(".search__input").addEventListener("input", function () {
        //var min_length = 3;
        var inst_text = document.querySelector(".search__instructional-text");
        if (this.value.length > min_length) {
          inst_text.classList.add("search__instructional-text--open");
        } else {
          inst_text.classList.remove("search__instructional-text--open");
        }
      });
    }
    if (undefined != document.querySelector(".search__icon-btn")) {
      document.querySelector(".search__icon-btn").addEventListener("click", doSearch);
    }
    if (undefined != document.querySelector(".search__form")) {
      document.querySelector(".search__form").addEventListener("submit", doSearch);
    }

    /**
     * leftPanelSubMenu
     */
    var menuList = $(".mobile-navigation__submenu > ul");

    //var url = $(location).attr('href');

    var page_Assets = [{
      logos: 2
    }, {
      ascender: 27
    }, {
      templates: 21
    }, {
      lightstream: 26
    }, {
      colors: 13
    }, {
      typography: 20
    }, {
      photography: 19
    }, {
      icons: 14
    }, {
      "micro-illustrations": 17
    }, {
      illustrations: 15
    }, {
      digital: 24
    }, {
      advertising: 23
    }, {
      "merchandising-events": 25
    }, {
      music: 18
    }, {
      video: 22
    }];
    page_Assets.forEach(function (page) {
      for (var prop in page) {
        var get_menu = void 0;
        if (document.querySelector("#dc_jqaccordion_widget-" + page[prop])) {
          get_menu = Array.from(document.querySelector("#dc_jqaccordion_widget-" + page[prop]).children);
          for (var i = 0; i < get_menu.length; i++) {
            var path = get_menu[i].children[0].href;
            var owl_location = document.querySelectorAll("[data-filter]");
            for (var j = 0; j < owl_location.length; j++) {
              if (owl_location[j].classList.contains("active")) {
                var active_owl_item = Array.from(owl_location[j].dataset.filter).slice(1).join("");
                if (prop === active_owl_item) {
                  var sidePanLogList = "";
                  sidePanLogList += "<li>" + '<a href="' + path + '">' + get_menu[i].children[0].textContent.toUpperCase() + "</a>" + "</li>";
                  menuList.append(sidePanLogList);
                }
              }
            }
          }
        }
      }
    });

    // this is to swap out text on assets/icons/download for spanish and english
    /*var IconDownTextSp = $('.elementor-6839');
    var IconDownTextEn = $('.elementor-1518');
    if (url.indexOf('/es/private/icons-downloads') > -1) {
        IconDownTextSp.css('display', 'block');
        IconDownTextEn.css('display', 'none');
    } else if(url.indexOf('/private/icons-downloads') > -1) {
        IconDownTextSp.css('display', 'none');
    }*/

    /**
     * tab_navigation
     */
    var tabNavigations = $(".tab__navigation").not("loaded");
    tabNavigations.each(function (pos, element) {
      var tab = $(element);
      tab.addClass("loaded");
      var line = null;
      var owlCarousel = $(".owl-carousel", tab);
      var hasOwlCarousel = owlCarousel.length > 0;
      var submenu = null;
      var mobileSubmenu = null;
      var hasSubmenu = tab[0].hasAttribute("data-submenu");
      if (hasSubmenu) {
        submenu = $(tab.attr("data-submenu"));
        mobileSubmenu = $("select.mobile-navegation");
      }
      var nextSection = null;
      var hasNextSection = tab[0].hasAttribute("data-next");
      if (hasNextSection) {
        nextSection = $(tab.attr("data-next"));
      }
      var links = $("a", tab);
      var select = $("select.mobile-navegation", tab).first();
      var getInitialLinkElement = function getInitialLinkElement() {
        var initial = links.filter(".active").first();
        if (!initial.length > 0) {
          if (hasSubmenu) {
            var activeLink = $(".menu-link.active", submenu).first();
            if (activeLink.length > 0) {
              var activeGroup = activeLink.parents(".widget").first().parent();
              var _id = $.trim(activeGroup.attr("id"));
              if (_id !== "") {
                initial = links.filter('[data-filter="#' + _id + '"]').first();
              }
            }
          }
          if (!initial.length > 0) {
            initial = links.first();
          }
          initial.addClass("active");
        }
        return initial;
      };
      var getInitialOptionElement = function getInitialOptionElement() {
        var options = select.find("option");
        var option = options.filter("[selected],:selected").first();
        if (!option.length > 0) {
          option = options.first();
          option.prop("selected", "selected");
        }
        return option;
      };
      var updateLine = function updateLine(item) {
        if (line !== null) {
          var left = 0;
          var width = 0;
          if (hasOwlCarousel) {
            var container = item.parents(".owl-item").first();
            var position = container.position();
            if (position) {
              left = position.left;
              width = container.outerWidth();
            }
          } else {
            left = item.position().left;
            width = item.outerWidth();
          }
          line.css("left", left + "px");
          line.css("width", width + "px");
        }
      };
      var filterList = function filterList(filter, list) {
        $(list).fadeOut("fast");
        if (filter === "All" || filter === "Todos") {
          $(list).each(function () {
            $(this).delay(200).fadeIn("fast");
          });
        } else {
          $(list).filter('[datacategory="' + filter + '"]').each(function () {
            $(this).delay(200).fadeIn("fast");
          });
        }
      };
      var onChangeElement = function onChangeElement() {};

      /*
       * We are rebuilding the mobile submenu by an issue that exists in safari 10
       * to hide the options and optgroups
       */
      var buildMobileSubmenu = function buildMobileSubmenu(activeLink) {
        if (mobileSubmenu && mobileSubmenu.length > 0) {
          // Remove all child nodes
          mobileSubmenu.empty();
          $("> div.show", submenu).each(function (pos, group) {
            var $group = $(group);
            var groupId = $group.attr("id");
            var activeLinkUrl = activeLink.prop("href");
            var menuItems = $("> .accordion > .menu-item", $group);
            menuItems.each(function (pos, item2) {
              var $item2 = $(item2);
              var menuLink = $(".menu-link[href]", $item2).first();
              var menuLinkHref = menuLink.prop("href");
              var name = menuLink.text();
              var value = groupId + "-" + name;
              if (menuLink.hasClass("open-list")) {
                var groupMenuName = menuLink.text();
                var optgroup = $("<optgroup>");
                optgroup.addClass("level-1");
                optgroup.prop("label", "  " + groupMenuName.toUpperCase());
                optgroup.attr("data-group-id", "#" + groupId);
                optgroup.appendTo(mobileSubmenu);
                if (menuLinkHref.indexOf("#") === -1) {
                  name = "Default";
                  value = groupId + "-" + groupMenuName;
                  var defaultOption = $("<option>");
                  defaultOption.addClass("level-2");
                  defaultOption.attr("data-url", menuLinkHref);
                  defaultOption.attr("value", value);
                  defaultOption.html(name);
                  if (activeLinkUrl === menuLinkHref) {
                    defaultOption.prop("selected", "selected");
                  }
                  defaultOption.appendTo(optgroup);
                }
                var menuLinks = $(".secondary-list .menu-link[href]", $item2);
                menuLinks.each(function (pos, item3) {
                  var menuLink2 = $(item3);
                  var menuLinkHref = menuLink2.prop("href");
                  if (menuLinkHref.indexOf("#") === -1) {
                    var name = menuLink2.text();
                    var value = groupId + "-" + name;
                    var option = $("<option>");
                    option.addClass("level-2");
                    option.attr("data-url", menuLinkHref);
                    option.attr("value", value);
                    option.html(name);
                    if (activeLinkUrl === menuLinkHref) {
                      option.prop("selected", "selected");
                    }
                    option.appendTo(optgroup);
                  }
                });
              } else if (menuLinkHref.indexOf("#") === -1) {
                var option = $("<option>");
                option.addClass("level-1");
                option.attr("value", value);
                option.attr("data-url", menuLinkHref);
                option.attr("data-group-id", "#" + groupId);
                option.html(name.toUpperCase());
                if (activeLinkUrl === menuLinkHref) {
                  option.prop("selected", "selected");
                }
                option.appendTo(mobileSubmenu);
              }
            });
          });
          return true;
        }
        return false;
      };
      var createMobileSubmenu = function createMobileSubmenu(activeLink) {
        if (buildMobileSubmenu(activeLink)) {
          mobileSubmenu.change(function () {
            var $this = $(this);
            var options = $this.find("option");
            var option = options.filter(":selected").first();
            if (option.length > 0) {
              if (option.hasAttr("data-url")) {
                var url = $.trim(option.attr("data-url"));
                if (url !== "") {
                  // The request must be made via ajax
                  window.location.href = url;
                }
              }
            }
          });
        }
      };
      var updateMobileSubmenu = function updateMobileSubmenu(activeLink) {
        buildMobileSubmenu(activeLink);
      };
      var buildSubmenu = function buildSubmenu(activeMenuElement) {
        var activeElement = null;
        var list = $("> div", submenu);
        //                        console.log('list: ', list);
        list.removeClass("show");
        var links = $(".menu-link", submenu);
        var activeLink = links.filter(".active").first();
        if (activeLink.length > 0) {
          activeElement = activeLink.parents(".widget").first().parent();
        } else {
          var selector = activeMenuElement.attr("data-filter");
          if (selector && $.trim(selector) !== "") {
            activeElement = list.filter($.trim(selector));
          }
          if (activeElement != null) {
            activeLink = activeElement.find(".menu-link").first();
          }
        }
        if (activeElement) {
          activeElement.addClass("show");
          if (activeLink.hasClass("open-list")) {
            activeLink.addClass("selected");
          }
          var secondList = activeLink.parents(".secondary-list").first();
          if (secondList.length > 0) {
            secondList.parent().find("a.open-list").addClass("selected");
          }
        }
        links.click(function () {
          var $this = $(this);
          links.removeClass("active").removeClass("selected");
          $this.addClass("active").addClass("selected");
          var secondList = $this.parents(".secondary-list").first();
          if (secondList.length > 0) {
            secondList.parent().find("a.open-list").addClass("selected");
          }
        });
        createMobileSubmenu(activeLink);
      };
      var updateSubmenu = function updateSubmenu(activeMenuElement) {
        var list = $("> div", submenu);
        list.removeClass("show");
        $(".menu-link", submenu).removeClass("active");
        var selector = activeMenuElement.attr("data-filter");
        if (selector && $.trim(selector) !== "") {
          var activeElement = list.filter($.trim(selector));
          activeElement.addClass("show");
          var activeLink = activeElement.find(".menu-link").first();
          activeLink.addClass("active");
          if (activeLink.hasClass("open-list")) {
            activeLink.addClass("selected");
          }
          updateMobileSubmenu(activeLink);
        }
      };
      var updateNextSection = function updateNextSection(activeMenuElement, nextLink) {
        if (!nextLink) {
          nextLink = $("a", nextSection).first();
        }
        if (nextLink.length > 0) {
          var nextElement = activeMenuElement.parent().next().find("a").first();
          if (!nextElement.length > 0) {
            nextElement = activeMenuElement.parent().siblings().first().find("a").first();
          }
          //                            console.log(nextElement);
          nextLink.attr("href", nextElement.attr("href"));
          nextLink.find(".name").text(nextElement.text());
        }
      };
      var buildNextSection = function buildNextSection(activeMenuElement) {
        //                        console.log('buildNextSection-init');
        var nextLink = $("a", nextSection).first();
        updateNextSection(activeMenuElement, nextLink);
        nextLink.click(function () {
          // Ajax way
        });
      };

      // Desktop navegation
      var attachEventsToLinks = function attachEventsToLinks() {
        links.click(function (e) {
          links.removeClass("active");
          var $this = $(this);
          $this.addClass("active");
          updateLine($this);
          if ($this.hasAttr("data-filter") && tab.hasAttr("data-filter-list")) {
            e.preventDefault();
            var filter = $this.attr("data-filter");
            var list = $(tab.attr("data-filter-list"));
            filterList(filter, list);
          } else {
            // ajax way
          }
          if (hasSubmenu) {
            updateSubmenu($this);
          }
          if (hasNextSection) {
            updateNextSection($this);
          }
          onChangeElement($this);
        });
        links.mouseover(function () {
          updateLine($(this));
        });
        links.mouseleave(function () {
          var initial = $("a.active", tab);
          if (initial.length > 0) {
            updateLine(initial);
          }
        });
      };

      // Mobile navegation
      var attachEventsToSelect = function attachEventsToSelect() {
        // For mobile navegation
        select.change(function () {
          var $this = $(this);
          var options = select.find("option");
          //                            console.log(options);
          var option = options.filter(":selected").first();
          //                            console.log(option);
          options.removeAttr("selected");
          if (option.length > 0) {
            option.prop("selected", "selected");
            if (option.hasAttr("data-filter") && tab.hasAttr("data-filter-list")) {
              var filter = option.attr("data-filter");
              var list = $(tab.attr("data-filter-list"));
              filterList(filter, list);
            } else if (option.hasAttr("data-url")) {
              var url = $.trim(option.attr("data-url"));
              if (url !== "") {
                // The request must be made via ajax
                window.location.href = url;
              }
            }
            onChangeElement($this);
          }
        });
      };
      var buildContainer = function buildContainer(owlContainer) {
        owlContainer = $(".owl-stage", owlCarousel);
        //                        console.log('container: ', owlContainer);
        if (!owlContainer.hasClass("loaded")) {
          owlContainer.addClass("loaded");
          line = tab.find(".line");
          line.appendTo(owlContainer);
          var initialLinkElement = getInitialLinkElement();
          updateLine(initialLinkElement);
          getInitialOptionElement();
          if (hasSubmenu) {
            buildSubmenu(initialLinkElement);
          }
          if (hasNextSection) {
            buildNextSection(initialLinkElement);
          }
          //attachEventsToLinks();
          attachEventsToSelect();
        }
      };
      if (hasOwlCarousel) {
        var owlContainer = $(".owl-stage", owlCarousel);
        if (owlContainer.length > 0) {
          buildContainer(owlContainer);
        } else {
          owlCarousel.one("DOMSubtreeModified", function () {
            buildContainer(owlContainer);
          });
        }
      } else {
        line = $(".line", tab);
        var initialLinkElement = getInitialLinkElement();
        updateLine(initialLinkElement);
        getInitialOptionElement();
        if (hasSubmenu) {
          buildSubmenu(initialLinkElement);
        }
        if (hasNextSection) {
          buildNextSection(initialLinkElement);
        }
        attachEventsToLinks();
        attachEventsToSelect();
      }
    });

    /**
     * mega_menu
     */
    // Selectors

    var modal = null;
    var modalList = Array.from(document.querySelectorAll(".modal"));
    var id = Math.floor(Math.random() * 9999);
    var closeMenuButton = document.querySelectorAll(".modal__button--close");
    var openMenuButton = document.querySelectorAll(".modal__button--open");
    var animationDelay = 250;

    //Fetch megamenu content
    fetch("https://assets.bbvaexperience.com/experience/megamenu.json").then(function (response) {
      if (response.ok) {
        response.json().then(function (json) {
          beforeShowMegaMenu(json);
        });
      } else {
        console.log("Network request failed with response " + response.status + ": " + response.statusText);
      }
    }).then(function () {
      // Events
      closeMenuButton.forEach(function (closebtn) {
        closebtn.addEventListener("click", function (ev) {
          close(ev);
        });
      });
      openMenuButton.forEach(function (openbtn) {
        openbtn.addEventListener("click", function (ev) {
          open(ev);
        });
      });
    });

    // Functions
    modalList.forEach(function (el) {
      el.dataset.modal_id = id;
    });
    function beforeShowMegaMenu(dataResponse) {
      var ulContainer = document.querySelectorAll(".modal__nav__container");
      for (var item in dataResponse) {
        var test = dataResponse[item];
        var li = document.createElement("li");
        var anchor = document.createElement("a");
        var img = document.createElement("img");
        var span = document.createElement("span");
        ulContainer[0].appendChild(li).className = "modal__nav__item";
        anchor.className = "modal__animated-anchor";
        anchor.id = "modal-" + test.name.replace(/ /g, "-").toLowerCase();
        anchor.href = test.link;
        anchor.title = test.name + " site";

        // add active if test.link matches domain url
        if (window.location) {
          if (window.location.origin.indexOf(test.link) > -1) {
            anchor.classList.add("active");
          }
        }
        anchor.appendChild(img).setAttribute("src", test.img);
        anchor.appendChild(span).innerHTML = test.name;
        li.appendChild(anchor);
      }

      // test if this is a even or odd number
      var isEven = dataResponse.length % 2 === 0 ? true : false;
      var container = document.querySelector(".modal__nav__container");
      if (isEven) {
        container.classList.add("even");
      } else {
        container.classList.add("odd");
      }
    }
    function open(e) {
      var menuCount = 0;
      modal = document.querySelector(e.currentTarget.dataset.modal);
      if (modal == null) {
        return false;
      }
      modal.classList.add("modal--in");
      document.querySelectorAll(".modal__nav__item a").forEach(function (el) {
        setTimeout(function () {
          el.classList.add("show");
        }, animationDelay * menuCount);
        menuCount++;
      });

      // We hold the current target in memory

      //var targetRef = event.currentTarget;
    }

    function close() {
      var menuCount = 0;
      if (modal) {
        modal.classList.remove("modal--in");
      }
      document.querySelectorAll(".modal__nav__item a").forEach(function (el) {
        setTimeout(function () {
          el.classList.remove("show");
        }, animationDelay * menuCount);
        menuCount++;
      });
    }

    /**
     * megaNavToggle
     */
    var mainNav = Array.from(document.querySelector(".primary_nav").children);
    var mobile_nav;
    if (document.querySelectorAll("#menu-primary-nav-spanish-1.primary_nav") !== null && document.querySelectorAll("#menu-primary-nav-spanish-1.primary_nav")[0] !== undefined) {
      mobile_nav = Array.from(document.querySelectorAll("#menu-primary-nav-spanish-1.primary_nav")[0].children);
    } else if (document.querySelectorAll("#menu-primary-nav.primary_nav") !== null && document.querySelectorAll("#menu-primary-nav-spanish-1.primary_nav")[0] !== undefined) {
      mobile_nav = Array.from(document.querySelectorAll("#menu-primary-nav.primary_nav")[0].children);
    } else if (document.querySelector("#menu-primary-nav-1.primary_nav") !== null && document.querySelector("#menu-primary-nav-1.primary_nav")[0] !== undefined) {
      mobile_nav = Array.from(document.querySelectorAll("#menu-primary-nav-1.primary_nav")[0].children);
    }

    //console.log(mobile_nav);
    // else {
    //     return false;
    // }

    mainNav.forEach(function (el) {
      //console.log(el.children[0].textContent)
      if (el.classList.contains("active")) {
        var active_page;
        if (el.children) {
          active_page = el.children[0].textContent;
        }
        if (mobile_nav !== undefined) {
          for (var i = 0; i < mobile_nav.length; i++) {
            if (mobile_nav[i].classList.contains("active")) {
              if (mobile_nav[i].children[0].textContent === active_page) {
                mobile_nav[i].classList.add("active");
              }
            }
          }
        }
      }
    });

    //console.log(mainNav);

    /* document.querySelector('[data-toggle-mega]').addEventListener('click', function(){
       this.classList.toggle('active');
      if(this.nextElementSibling.style.height){
        this.nextElementSibling.style.height = null;
      } else {
        this.nextElementSibling.style.height = this.nextElementSibling.scrollHeight + 'px';
      }
    }); */

    document.querySelectorAll("[data-toggle-mega]")[0].addEventListener("click", function (e) {
      open(e);
    });
    if (document.querySelectorAll(".modal__button--close").length) {
      document.querySelectorAll(".modal__button--close")[0].addEventListener("click", function (ev) {
        close(ev);
      });
    }

    /**
     * requestForm
     */
    // 	https://ourbrand.bbvaexperience.com = jdluxcpu
    //  https://brand.bbvaexperience.com = j95d9zta

    var firstName, lastName, companyName, email, bbvaEmail, projectName, locationId, telephoneNumber, password;
    if (window.location.hostname === "ourbrand.bbvaexperience.com") {
      locationId = "jdluxcpu";
      if (document.querySelector(".sec-page__rightpanel")) {
        document.querySelector(".sec-page__rightpanel").classList.add("center");
      }
    } else if (window.location.hostname === "brand.bbvaexperience.com" || window.location.hostname === "ec2-34-253-33-245.eu-west-1.compute.amazonaws.com") {
      if (document.querySelector(".newUser")) {
        document.querySelector(".newUser").classList.remove("hide");
      }
      locationId = "j95d9zta";
    }

    // Generate password
    //The password must contain 8 characters, at least two numbers and / or two
    //letters and can not contain special characters such as Ñ or Ç or have been
    //previously used.
    /* function randomPassword(length) {
      //var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
      var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890';
      var pass = '';
      for (var x = 0; x < length; x++) {
        var i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
      }
      return pass;
    }
     var genPassword = randomPassword(15);
     if (document.querySelector('#form_j95d9zta1520280436687')) {
      document.querySelector('#form_j95d9zta1520280436687').addEventListener('submit', function () {
        //console.log('clicked from main.js');
         firstName = document.getElementById("firstname").value;
        lastName = document.getElementById("lastname").value;
        companyName = document.getElementById("companyname").value;
        email = document.getElementById("companyemail").value.toLowerCase();
        bbvaEmail = document.getElementById("bbvaemail").value.toLowerCase();
        projectName = document.getElementById("projectname").value;
        telephoneNumber = document.getElementById("telephone").value;
        password = genPassword;
         var options = {
          method: "POST",
          type: "POST",
          url:
            window.location.protocol +
            "//" +
            window.location.hostname +
            "/bbva-components/authbbva" +
            "/?project=" +
            locationId,
          dataType: "json",
          contentType: "application/json",
        };
         var data = {
          email: email,
          username: email,
          password: window.btoa(password),
          telephone: telephoneNumber,
          custom: {
            firstname: firstName,
            lastname: lastName,
            companyname: companyName,
            bbvacontact: bbvaEmail,
            project: projectName,
          },
        };
         //console.log([firstName,lastName, companyName, email, bbvaEmail, projectName]);
        // console.log(options);
        // console.log(data);
         options.data = JSON.stringify(data);
         jQuery
          .ajax(options)
          .done(function () {
            console.log("Sign-up SUCCESS");
          })
          .fail(function (err) {
            console.log("Error: ", err);
          });
      });
    } */

    //if (document.querySelector("#form_j95d9zta1697011961592")) {
    if (document.querySelector("#form_j95d9zta1520280436687")) {
      document
      //.querySelector("#form_j95d9zta1697011961592")
      .querySelector("#form_j95d9zta1520280436687").addEventListener("submit", function () {
        firstName = document.getElementById("firstname").value;
        lastName = document.getElementById("lastname").value;
        companyName = document.getElementById("companyname").value;
        email = document.getElementById("companyemail").value.toLowerCase();
        bbvaEmail = document.getElementById("bbvaemail").value.toLowerCase();
        projectName = document.getElementById("projectname").value;
        telephoneNumber = document.getElementById("telephone").value;
        password = document.getElementById("password").value;
        var options = {
          method: "POST",
          type: "POST",
          url: window.location.protocol + "//" + window.location.hostname + "/bbva-components/authbbva" + "/?project=" + locationId,
          dataType: "json",
          contentType: "application/json"
        };
        var data = {
          email: email,
          username: email,
          password: window.btoa(password),
          telephone: telephoneNumber,
          custom: {
            firstname: firstName,
            lastname: lastName,
            companyname: companyName,
            bbvacontact: bbvaEmail,
            project: projectName
          },
          optional_data: {
            name: firstName,
            surname: lastName
          }
        };

        //console.log([firstName,lastName, companyName, email, bbvaEmail, projectName]);
        // console.log(options);
        // console.log(data);

        options.data = JSON.stringify(data);
        jQuery.ajax(options).done(function () {
          console.log("Sign-up SUCCESS");
        }).fail(function (err) {
          console.log("Error: ", err);
        });
      });
    }

    /**
     * Sticky
     */
    var stickyElements = $("[data-sticky]");
    var checkStickyElement = function checkStickyElement(element, scrollTop) {
      //                    console.log('checkStickyElement-init');
      var $element = $(element);
      var topPosition = $element.parent().offset().top;
      if (scrollTop >= topPosition) {
        if (!$element.hasClass("sticky")) {
          $element.addClass("sticky");
        }
      } else {
        if ($element.hasClass("sticky")) {
          $element.removeClass("sticky");
        }
      }
    };
    stickyElements.each(function (pos, element) {
      var scrollTop = $(window).scrollTop();
      checkStickyElement(element, scrollTop);
    });
    $(window).scroll(function () {
      var scrollTop = $(window).scrollTop();
      stickyElements.each(function (pos, element) {
        checkStickyElement(element, scrollTop);
      });
    });
    $(window).resize(function () {
      var scrollTop = $(window).scrollTop();
      stickyElements.each(function (pos, element) {
        checkStickyElement(element, scrollTop);
      });
    });

    /**
     * User Info
     */
    var userText = document.querySelector(".link-container ul li.userinfo");
    var location;
    //console.log(userText.textContent);

    //function reqListener(obj) {
    //var user = JSON.parse(obj.responseText);
    //console.log(user.data.userId);
    //  userText.textContent = user.data.userId;
    //}

    if (window.location.hostname === "ourbrand.bbvaexperience.com") {
      location = "jdluxcpu";
      var oReq = new XMLHttpRequest();
      oReq.open("get", window.location.protocol + "//" + window.location.hostname + "/bbva-components/utils/info?&project=" + location, true);
      oReq.onload = function () {
        if (oReq.readyState === oReq.DONE) {
          if (oReq.status === 200) {
            var user = JSON.parse(oReq.responseText);
            //userText.textContent = user.data.userId;
            userText.innerHTML = '<span><i class="ui-my-profile" ></i ></span> ' + user.data.userId;
          }
        }
      };
      oReq.send();

      //reqListener(userObj);
    } else {
      location = "j95d9zta";
    }

    /**
     * Widgets
     */
    var colorElements = document.querySelectorAll("[data-color-hex]");
    function getHexId(i) {
      i.forEach(function (e) {
        e.addEventListener("click", function (text) {
          this.text = text;
          this.text = e.dataset.colorHex;
          // This must be triggered by a user event.

          // Create the textarea input to hold our text.
          var element = document.createElement("textarea");
          // element.style.visibility = 'hidden';
          element.value = this.text;
          // Add it to the document so that it can be focused.
          document.body.appendChild(element);
          // Focus on the element so that it can be copied.
          element.select();
          element.setSelectionRange(0, element.value.length);
          // Execute the copy command.
          document.execCommand("copy");
          // Remove the element to keep the document clear.
          document.body.removeChild(element);

          // setTimeout(myFunction, 2000)

          e.classList.remove("ui-copy");
          e.classList.add("ui-checkmark-filled");
          setTimeout(function () {
            e.classList.remove("ui-checkmark-filled");
            e.classList.add("ui-copy");
          }, 1000);
        });
      });
    }
    getHexId(colorElements);

    /**
     * Fancybox
     */
    $("a.watch-video").fancybox({
      showCloseButton: false
    });

    /**
     * Forms
     */
    var domainsBBVARegEx = /@(bbva|garantibbva|bbvacreative|bbvaam).com/;
    $.validator.addMethod("emailList", function (value, element) {
      if (this.optional(element)) {
        return true;
      }
      var emails = value.toLowerCase().split(",");
      var result = true;
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      emails.forEach(function (email) {
        if (!re.test(email.trim())) {
          result = false;
          return;
        }
      });
      return result;
    }, "Invalid email list");
    $.validator.addMethod("emailListSize", function (value, element, params) {
      if (this.optional(element)) {
        return true;
      }
      var emails = value.toLowerCase().split(",");
      if (params) {
        var max = params;
        if (emails.length > max) {
          return false;
        }
      }
      return true;
    }, "The list must have a maximum of {0} elements.");
    $.validator.addMethod("fileSize", function (value, element, size) {
      if (this.optional(element)) {
        return true;
      }
      var bytes = element.files[0].size;
      var mb = bytes / 1024 / 1024;
      if (mb > size) {
        return false;
      }
      return true;
    }, "Max. limit {0} MB.");
    $.validator.addMethod("dateFormat", function (value) {
      //TO DO: validar por idioma
      var mmddyyy = new RegExp(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/);
      //var ddmmyyy = new RegExp(/^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/);
      //var regexp = new RegExp(/^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])(\/)\d{4}$/);
      if (value.match(mmddyyy) !== null) {
        return true;
      } else {
        return false;
      }
    }, "Please enter in the format mm/dd/yyyy.");
    $.validator.addMethod("bbvaEmail", function (value, element) {
      if (this.optional(element)) {
        return true;
      }
      return domainsBBVARegEx.test(value);
    }, "Must be a valid BBVA domain");
    $.validator.addMethod("passwordRegEx", function (value, element) {
      if (this.optional(element)) {
        return true;
      }
      var regex = /^(?=(?:.*?[A-Za-z]){2})(?=(?:.*?[0-9]){2})[A-Za-z0-9]{8,}$/;
      return regex.test(value);
    }, "Must contain 2 numbers or letters and no Ñ or Ç");
    $.validator.addMethod("passwordMatch", function (value, element) {
      if (this.optional(element)) {
        return true;
      }
      var password = document.getElementById("password").value;
      return password === value;
    }, "Passwords must match");
    var formRules = {
      "request-form-istock-getty": {
        name: {
          required: true,
          minlength: 2,
          maxlength: 80
        },
        department: {
          required: true,
          minlength: 2,
          maxlength: 80
        },
        email: {
          required: true,
          email: true,
          minlength: 4,
          maxlength: 80
        }
      },
      "request-form-trademark": {
        name: {
          required: true,
          minlength: 2,
          maxlength: 80
        },
        department: {
          required: true,
          minlength: 2,
          maxlength: 80
        },
        email: {
          required: true,
          email: true
        },
        ccEmail: {
          required: true,
          emailList: true,
          emailListSize: 5
        },
        trademarkName: {
          required: true
        },
        launchDate: {
          //required: true,
          dateFormat: true,
          date: true
        },
        region: {
          required: true
        },
        questions: {
          required: true
        },
        file: {
          fileSize: 10
        }
      },
      "request-form-domain": {
        name: {
          required: true,
          minlength: 2,
          maxlength: 80
        },
        department: {
          required: true,
          minlength: 2,
          maxlength: 80
        },
        email: {
          required: true,
          email: true
        },
        ccEmail: {
          required: true,
          emailList: true,
          emailListSize: 5
        },
        domainName: {
          required: true
        },
        launchDate: {
          required: true,
          date: true
        },
        region: {
          required: true
        },
        questions: {
          required: true
        },
        file: {
          fileSize: 10
        }
      },
      "request-form-assets": {
        name: {
          required: true,
          minlength: 2,
          maxlength: 80
        },
        department: {
          required: true,
          minlength: 2,
          maxlength: 80
        },
        email: {
          required: true,
          email: true
        }
      },
      "request-form-photography": {
        name: {
          required: true,
          minlength: 2,
          maxlength: 80
        },
        department: {
          required: true,
          minlength: 2,
          maxlength: 80
        },
        email: {
          required: true,
          email: true,
          minlength: 4,
          maxlength: 80
        },
        emailBBVA: {
          required: true,
          email: true,
          minlength: 4,
          maxlength: 80,
          bbvaEmail: true
        },
        date: {
          required: true,
          date: true
        }
      },
      "help-form": {
        name: {
          required: true,
          minlength: 2,
          maxlength: 80
        },
        department: {
          required: true,
          minlength: 2,
          maxlength: 80
        },
        email: {
          required: true,
          email: true
        },
        description: {
          required: true
        }
      },
      "signup-form": {
        password: {
          required: true,
          minlength: 2,
          passwordRegEx: true
        },
        password2: {
          required: true,
          minlength: 2,
          passwordMatch: true
        }
      }
    };

    // Add fe-not-empty class to inputs and textareas
    var checkEmptyElement = function checkEmptyElement(value, container) {
      var input = value;
      if (value.target) {
        input = value.target;
      }
      var classList = "";
      if (container === true) {
        classList = $(input).parent()[0].classList;
      } else {
        classList = input.classList;
      }
      if (input.value && input.value.replace(/^\s+|\s+$/g, "")) {
        classList.add("fe-not-empty");
      } else {
        if (classList.contains("fe-not-empty")) {
          classList.remove("fe-not-empty");
        }
      }
    };

    // form dropdown Start
    var initDropdown = function initDropdown(container) {
      var formDropdowns = $(".form-dropdown", container).not(".loaded");
      formDropdowns.each(function (pos, element) {
        var formDropdown = $(element);
        formDropdown.addClass("loaded");
        var formGroup = $(".form-group", formDropdown);
        var input = $("input", formGroup);
        formGroup.on("click keydown", function (e) {
          //                        console.log(e.type, e.keyCode, e.charCode);
          if (e.type === "click" || e.type === "keydown" && (e.keyCode === 32 || e.keyCode === 38 || e.keyCode === 40)) {
            $(this).closest(".form-dropdown").find("select").slideToggle(110);
            $(this).closest(".form-dropdown").find("select:first").focus();
            $(this).parent().toggleClass("dd-open");
          }
        });
        var select = $("select", formDropdown);
        select.on("change", function () {
          formDropdown.toggleClass("dd-open");
          select.hide();
          input.val(select.find("option:selected").text());
          input.focus();
        });
        var option = select.find("option[selected], option:selected").first();
        if (option.exists()) {
          input.val(option.text());
        }
      });
    };
    initDropdown();
    var forms = $("form.brand-form");
    //                console.log(forms.length);
    forms.each(function (pos, element) {
      //                    console.log(element);
      //                    var is_sending = false;
      var form = $(element);
      var name = form.attr("data-name");
      var submitBtn = $(".btn-submit", element);
      var validator = form.validate({
        success: "valid",
        errorElement: "label",
        errorPlacement: function errorPlacement(error, element) {
          error.appendTo(element.parent());
        },
        rules: formRules[name] ? formRules[name] : {}
      });
      form.submit(function () {
        var selector = submitBtn.attr("data-target");
        if (selector) {
          var target = $(selector);
          if (target.length === 1) {
            var modal = target.find(".modal");
            if (modal.length === 1) {
              modal.addClass("modal--in");
            } else {
              target.addClass("in");
            }
          }
        }
        setTimeout(function () {
          element.reset();
          form.parents(".modal").first().removeClass("modal--in");
        }, 1000);
      });
      var validateForm = function validateForm() {
        validator.form();
        var result = form.valid();
        if (result) {
          submitBtn.removeClass("btn-disabled");
          return true;
        }
        submitBtn.addClass("btn-disabled");
        return false;
      };
      submitBtn.click(function (e) {
        if (!validateForm()) {
          e.preventDefault();
        }
      });
      var validateActiveElement = function validateActiveElement(value) {
        var element = value;
        if (value.target) {
          element = value.target;
        }
        $(element).removeClass("pure");
        if (validator.element(element)) {
          var form = $(element).parents("form").first();
          var currentActiveElements = $('input[type="text"], input[type="email"], textarea', form).not(".template").not("[disabled]").not("[readonly]");
          //                            console.log('currentActiveElements: ', currentActiveElements);
          //                            console.log('pure: ', currentActiveElements.filter('.pure'));
          if (currentActiveElements.filter(".pure").length === 0) {
            if (form.valid()) {
              submitBtn.removeClass("btn-disabled");
            } else {
              submitBtn.addClass("btn-disabled");
            }
          }
        } else {
          submitBtn.addClass("btn-disabled");
        }
      };
      var attachOnClickOnFileUploads = function attachOnClickOnFileUploads(elements) {
        elements.each(function (pos, container) {
          var $container = $(container);
          $(".box.add", container).click(function (e) {
            e.preventDefault();
            var box = $(this);
            var template = $(".file.template", container);
            var div = template.clone();
            div.removeClass("template");
            div.addClass("hide");
            var input = $('input[type="file"]', div);
            input.bind("change", function () {
              //                                e.preventDefault();
              var filePath = $(this).val();
              if (filePath.length > 0) {
                var fileName = filePath.substring(filePath.lastIndexOf("\\") + 1);
                div.find(".name").text(fileName);
                input.attr("name", $container.attr("data-name"));
                div.appendTo(container);
                div.removeClass("hide");
                box.addClass("hide");
                validateActiveElement(input);
              }
            });
            $("button.remove", div).click(function (e) {
              e.preventDefault();
              div.remove();
              box.removeClass("hide");
              $("label.error", $container.parent()).addClass("valid");
            });
            input.trigger("click");
          });
        });
      };
      var elements = $('input[type="text"], input[type="email"], textarea', form).not(".template");
      elements.each(function (pos, element) {
        checkEmptyElement(element);
      });
      var activeElements = elements.not("[disabled]").not("[readonly]");
      activeElements.each(function (pos, element) {
        var item = $(element);
        item.blur(validateActiveElement);
        item.blur(checkEmptyElement);
        item.change(checkEmptyElement);
        item.focus(checkEmptyElement);
        item.keyup(checkEmptyElement);
        item.mousedown(checkEmptyElement);
        if ((item.prop("required") || item.attr("aria-required")) && item.val() === "") {
          item.addClass("pure");
        }
      });
      if (activeElements.filter(".pure").length === 0) {
        submitBtn.removeClass("btn-disabled");
      }
      attachOnClickOnFileUploads($(".fileupload", form));

      // Only for photography form
      if (form.hasClass("request-form-photography")) {
        var isBBVAEmail = function isBBVAEmail(item) {
          return domainsBBVARegEx.test(item);
        };
        $("input[type=email][name=email]", form).change(function () {
          var bbvaEmailRow = $("input[type=email][name=emailBBVA]", form).closest(".row");
          if (isBBVAEmail($(this).val())) {
            bbvaEmailRow.addClass("hide");
          } else {
            bbvaEmailRow.removeClass("hide");
          }
        });
      }

      // Only for assets form
      if (form.hasClass("request-form-assets")) {
        var checkWorkBbvaRadio = function checkWorkBbvaRadio(item) {
          //                        console.log('checkWorkBbvaRadio', item);
          if (item) {
            var companyNameInput = $("#companyName", form);
            var contactInput = $("#contact", form);
            if (item.value && item.value.toLowerCase() === "yes") {
              $(".workBbva-no", form).addClass("hide");
              companyNameInput.removeAttr("required");
              contactInput.removeAttr("required");
            } else {
              $(".workBbva-no", form).removeClass("hide");
              companyNameInput.prop("required", true);
              contactInput.prop("required", true);
            }
          }
        };
        $("input[type=radio][name=workBbva]", form).change(function () {
          checkWorkBbvaRadio(this);
          validateForm();
        });
        checkWorkBbvaRadio($("input[type=radio][name=workBbva]:checked", form)[0]);
        var assetList = $(".asset-list", form);
        var assetsLimit = 3;
        var counter = 0;
        var getAssetListSize = function getAssetListSize() {
          return assetList.find(".asset-box").not(".template").length;
        };
        var updateAssetListSize = function updateAssetListSize(size) {
          assetList.attr("data-size", size);
        };
        var addAsset = function addAsset() {
          var size = $(".asset-box", assetList).not(".template").length;
          if (size < assetsLimit) {
            counter++;
            var box = $(".asset-box.template", assetList).clone();
            var selectAssetInput = $("input.select-asset", box);
            selectAssetInput.attr("name", "select-asset[" + counter + "]");
            selectAssetInput.removeClass("template");
            selectAssetInput.prop("required", true);
            var fileUploadBox = $(".fileupload[data-name]", box);
            fileUploadBox.attr("data-name", "files[" + counter + "]");
            var fileInput = $("input.file", box);
            fileInput.attr("name", "files[" + counter + "]");
            fileInput.removeClass("template");
            fileInput.prop("required", true);
            var descriptionTextarea = $("textarea.description", box);
            descriptionTextarea.attr("name", "description[" + counter + "]");
            descriptionTextarea.removeClass("template");
            descriptionTextarea.prop("required", true);
            box.removeClass("template");
            box.appendTo(assetList);
            box.find(".form-dropdown").removeClass("loaded");
            $(".remove-asset-btn", box).click(function () {
              var currentSize = getAssetListSize();
              if (currentSize > 1) {
                $(this).parents(".asset-box").first().remove();
                updateAssetListSize(currentSize - 1);
              }
            });
            var elements = $('input[type="text"], input[type="email"], textarea', box).not(".template");
            elements.each(function (pos, element) {
              checkEmptyElement(element);
            });
            var activeElements = elements.not("[disabled]").not("[readonly]");
            activeElements.each(function (pos, element) {
              var item = $(element);
              item.blur(validateActiveElement);
              item.blur(checkEmptyElement);
              item.change(checkEmptyElement);
              item.focus(checkEmptyElement);
              item.keyup(checkEmptyElement);
              item.mousedown(checkEmptyElement);
              if ((item.prop("required") || item.attr("aria-required")) && item.val() === "") {
                item.addClass("pure");
              }
            });
            if (activeElements.filter(".pure").length === 0) {
              submitBtn.removeClass("btn-disabled");
            }
            attachOnClickOnFileUploads($(".fileupload", box));
            initDropdown(box);
            var checkAssetSelected = function checkAssetSelected(item) {
              var select = $(item);
              var box = select.parents(".asset-box").first();
              var forEmailInput = box.find(".forEmail");
              var currentOption = item.options[item.selectedIndex];
              if (currentOption) {
                forEmailInput.val(currentOption.getAttribute("data-for-email"));
                forEmailInput.addClass("fe-not-empty");
                forEmailInput.parents(".row").first().removeClass("hide");
              }
            };
            $("select.select-asset", box).each(function (pos, element) {
              $(element).change(function () {
                checkAssetSelected(this);
              });
              checkAssetSelected(element);
            });
            updateAssetListSize(getAssetListSize());
            submitBtn.addClass("btn-disabled");
          }
        };
        $(".add-asset-btn").click(function (e) {
          e.preventDefault();
          addAsset();
        });
        addAsset();
      }
    });
    $(".btn-end-flow").click(function (e) {
      e.preventDefault();
      $(this).parents(".modal").first().removeClass("modal--in");
    });
    var loginForm = $(".sec-form[data-component]").first();
    if (loginForm.length === 1) {
      loginForm.one("DOMSubtreeModified", function () {
        var elements = $('input[type="text"], input[type="email"], input[type="password"]', loginForm);
        elements.each(function (pos, element) {
          var item = $(element);
          item.focus(function (e) {
            checkEmptyElement(e, true);
            $(this).parent()[0].classList.add("fe-focus");
          });
          item.blur(function (e) {
            checkEmptyElement(e, true);
            var classList = $(this).parent()[0].classList;
            if (classList.contains("fe-focus")) {
              classList.remove("fe-focus");
            }
          });
          item.change(function (e) {
            checkEmptyElement(e, true);
          });
          item.keyup(function (e) {
            checkEmptyElement(e, true);
          });
          item.mousedown(function (e) {
            checkEmptyElement(e, true);
          });
          checkEmptyElement(element, true);
        });
      });
    }
  },
  finalize: function finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  }
};